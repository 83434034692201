<div class="card-body ng-star-inserted">
  <mat-horizontal-stepper [linear]="true" #stepper>
  <!-- Paso 1: Seleccionar Diseño -->
  <mat-step [stepControl]="firstFormGroup" label="Seleccionar Diseño">
    <!-- Paso 1: Selección de plantilla -->
    <div class="step-content">
      <div *ngIf="isEditing" class="alert alert-info mb-4">
        <i class="fas fa-edit me-2"></i> Estás editando una landing page existente
      </div>
      <h2 class="text-2xl font-bold mb-6 text-center text-gray-800" style="text-transform: none !important;">Selecciona el diseño de tu Landing Page</h2>
      <!-- Dentro del primer mat-step, actualiza el div con la clase "row g-4" -->
      <div class="row g-4 plantillas-container">
        <!-- Landing 1 -->
        <div class="col-md-5 col-lg-5 mx-auto border rounded-lg p-2 cursor-pointer transition-all duration-300 hover:shadow-lg"
            [class.border-blue-500]="plantillaSeleccionada === 'Landing1'"
            [class.shadow-lg]="plantillaSeleccionada === 'Landing1'"
            [class.plantilla-no-seleccionada]="plantillaSeleccionada && plantillaSeleccionada !== 'Landing1'"
            (click)="seleccionarPlantilla('Landing1')" 
            style="border-radius: 10px;">
          <h3 class="text-center text-base font-semibold mb-2 text-gray-700">Landing Page 1</h3>
          <div class="bg-white">
            <div class="browser-window w-100">
              <div class="browser-header">
                <div class="buttons">
                  <span class="button close"></span>
                  <span class="button minimize"></span>
                  <span class="button maximize"></span>
                </div>
              </div>
              <div class="browser-content" style="min-height: 600px; overflow-y: auto;">
                <app-landing-curso 
                  [previewMode]="true"
                  class="w-full h-full object-cover"
                  style="transform: scale(0.5); transform-origin: top left;">
                </app-landing-curso>
              </div>
            </div>
          </div>
        </div>

        <!-- Landing 2 -->
        <div class="col-md-5 col-lg-5 mx-auto border rounded-lg p-2 cursor-pointer transition-all duration-300 hover:shadow-lg"
            [class.border-blue-500]="plantillaSeleccionada === 'Landing2'"
            [class.shadow-lg]="plantillaSeleccionada === 'Landing2'"
            [class.plantilla-no-seleccionada]="plantillaSeleccionada && plantillaSeleccionada !== 'Landing2'"
            (click)="seleccionarPlantilla('Landing2')" 
            style="border-radius: 10px;">
          <h3 class="text-center text-base font-semibold mb-2 text-gray-700">Landing Page 2</h3>
          <div class="bg-white">
            <div class="browser-window w-100">
              <div class="browser-header">
                <div class="buttons">
                  <span class="button close"></span>
                  <span class="button minimize"></span>
                  <span class="button maximize"></span>
                </div>
              </div>
              <div class="browser-content" style="min-height: 600px; overflow-y: auto;">
                <app-landing2
                  [previewMode]="true"
                  class="w-full h-full object-cover"
                  style="transform: scale(0.5); transform-origin: top left;">
                </app-landing2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Agregar después del div con class="row g-4" -->
      <div class="mt-8 flex justify-end">
        <button
          matStepperNext
          [disabled]="!plantillaSeleccionada"
          class="btn btn-primary"
          (click)="guardarLayoutSeleccionado()"
          type="button">
          Siguiente
        </button>
      </div>
    </div>
  </mat-step>

  <!-- Paso 2: Completar Información -->
  <mat-step [stepControl]="secondFormGroup" label="Completar Información">
    <!-- Paso 2: Formulario existente -->
    <div class="row">
      <div class="col-md-4">
        <div *ngIf="!crearPlantilla"><!--ngIf*="!crearPlantilla"-->
          <form [formGroup]="infoForm" (ngSubmit)="onSubmit()">
    
            <div class="">
              <label class="form-label">Selecciona tus colores</label>
              <div class="d-flex gap-3 align-items-center">
                <div style="flex: 1;">
                  <label for="color1" class="form-label text-secondary">Color Primario</label>
                  <input type="color" formControlName="color1" [(ngModel)]="color1" (change)="guardarColores()" class="form-control form-control-color p-1 border-0 rounded shadow-sm" style="width: 100%; height: 40px;" required>
                </div>
                <div style="flex: 1;">
                  <label for="color2" class="form-label text-secondary">Color Secundario</label>
                  <input type="color" formControlName="color2" [(ngModel)]="color2" (change)="guardarColores()" class="form-control form-control-color p-1 border-0 rounded shadow-sm" style="width: 100%; height: 40px;" required>
                </div>
              </div>
            </div>               
    
            <div class="mb-3">
              <label for="logo" class="form-label">Logo o Marca</label>
              <p style="margin-bottom: 0px;">*La imagen debe ser menor o igual a 550px de ancho y 180px de alto.</p>
              <!-- Solo mostrar el input cuando no hay logo -->
              <input *ngIf="!logo" 
                     style="height: 38px !important;" 
                     type="file" 
                     (change)="onFileSelected($event, 'logo')"
                     class="form-control placeholder-text" 
                     required>
              <!-- Mostrar la imagen y botón de eliminar cuando existe logo -->
              <div *ngIf="logo" class="mt-3">
                  <img [src]="logo" alt="Logo o Marca" class="img-fluid" style="width: 100px;" />
                  <button type="button" class="edu-btnTransition" (click)="removeImage('logo')">Eliminar Imagen</button>
              </div>
            </div>
    
            <mat-form-field appearance="outline" class="mb-3 w-100">
              <mat-label>Tipografía</mat-label>
              <mat-select formControlName="tipografia" [(ngModel)]="tipografiaSeleccionada" (selectionChange)="guardarTipografia()" required>
                <mat-option value="" disabled>Seleccione una tipografía</mat-option>
                <mat-option *ngFor="let font of fuentes" [value]="font">{{ font }}</mat-option>
              </mat-select>
              <mat-hint *ngIf="isFieldInvalid('tipografia')" class="text-danger">
                Este campo es requerido.
              </mat-hint>
            </mat-form-field>        
    
            <mat-form-field class="w-100 mb-3" appearance="outline" floatLabel="always">
              <mat-label>Titulo Gancho</mat-label>
              <input matInput type="text" required class="w-100" formControlName="gancho" [(ngModel)]="gancho"
                placeholder="Titulo Gancho" [ngClass]="{'is-invalid': isFieldInvalid('gancho')}" required>
              <mat-error *ngIf="isFieldInvalid('gancho')">Este campo es requerido.</mat-error>
            </mat-form-field>
    
            
            <mat-form-field class="w-100 mb-3" appearance="outline" floatLabel="always">
              <mat-label>Propuesta de valor</mat-label>
              <input matInput type="text" formControlName="propuesta" [(ngModel)]="propuesta"
                placeholder="Propuesta de valor" [ngClass]="{'is-invalid': isFieldInvalid('propuesta')}" required>
              <mat-error *ngIf="isFieldInvalid('propuesta')">
                Este campo es requerido.
              </mat-error>
            </mat-form-field>
    
    
      
            <mat-form-field class="w-100 mb-3" appearance="outline" floatLabel="always">
              <mat-label>Deseo</mat-label>
              <input matInput type="text" formControlName="GatilloM" [(ngModel)]="deseo" placeholder="Deseo"
                [ngClass]="{'is-invalid': isFieldInvalid('deseo')}" required>
              <mat-error *ngIf="isFieldInvalid('deseo')">
                Este campo es requerido.
              </mat-error>
            </mat-form-field>
    
            
    
            <div class="mb-3">
              <label for="imagen" class="form-label">Imagen del curso</label>
              <p style="margin-bottom: 0px;">*La imagen debe ser menor o igual a 550px de ancho y 550px de alto.</p>
              <input *ngIf="!selectedImage" 
                     style="height: 38px !important;" 
                     type="file" 
                     (change)="onFileSelected($event, 'imagenCurso')"
                     class="form-control placeholder-text" 
                     required>
              <div *ngIf="selectedImage" class="mt-3">
                  <img [src]="selectedImage" alt="Imagen del curso" class="img-fluid" style="width: 100px;" />
                  <button type="button" class="edu-btnTransition" (click)="removeImage('imagenCurso')">Eliminar Imagen</button>
              </div>
            </div>
    
    
            <mat-form-field required class="w-100 mb-3" appearance="outline" style="margin-top: 1rem;">
              <mat-label>¿Qué se obtendra del curso?</mat-label>
              <mat-chip-grid #chipGrid>
                <mat-chip-row *ngFor="let obtendras of queObtendras; trackBy: trackByFn" (removed)="remove(obtendras)" [editable]="true"
                  [aria-description]="'press enter to edit ' + obtendras.name">
                  {{ obtendras.name }}
                  <a matChipRemove [attr.aria-label]="'remove ' + obtendras.name">
                    <mat-icon>cancel</mat-icon>
                  </a>
                </mat-chip-row>
                <input placeholder="Palabras clave" [matChipInputFor]="chipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)" formControlName="resAvatarNom" />
              </mat-chip-grid>
            </mat-form-field>
    
            <div class="mb-3">
              <label for="imgObtendras" class="form-label">Imagen apartado beneficios</label>
              <p style="margin-bottom: 0px;">*La imagen debe ser menor o igual a 550px de ancho y 550px de alto.</p>
              <input *ngIf="!imgObtendras" 
                     style="height: 38px !important;" 
                     type="file" 
                     (change)="onFileSelected($event, 'imgObtendras')"
                     class="form-control placeholder-text" 
                     required>
              <div *ngIf="imgObtendras" class="mt-3">
                  <img [src]="imgObtendras" alt="Imagen de beneficios" class="img-fluid" style="width: 100px;"/>
                  <button type="button" class="edu-btnTransition" (click)="removeImage('imgObtendras')">Eliminar Imagen</button>
              </div>
            </div>
            
            <div class="mb-3">
              <label class="form-label">¿Quien nos respalda?</label>
              <p style="margin-bottom: 0px;">*Las imágenes deben ser menor o igual a 550px de ancho y 550px de alto.</p>
              <input style="height: 38px !important;" 
                     type="file" 
                     (change)="onFileSelected($event, 'respaldo')"
                     class="form-control placeholder-text" 
                     multiple>
              <div *ngIf="imagenesRespaldo && imagenesRespaldo.length > 0" class="mt-3">
                  <div *ngFor="let image of imagenesRespaldo; let i = index">
                      <img [src]="image" alt="Imagen de respaldo" class="img-fluid" style="width: 100px;" />
                      <button style="height: 46px;" type="button" class="edu-btnTransition" (click)="removeImageRespald(i)">Eliminar Imagen</button>
                  </div>
              </div>
            </div>
          
            <mat-form-field class="w-100 mb-3" appearance="outline" floatLabel="always">
              <mat-label>¿Quiénes somos?</mat-label>
              <textarea matInput required class="w-100" [(ngModel)]="somos" formControlName="somos"
                placeholder="¿Quiénes somos?*" [ngClass]="{'is-invalid': isFieldInvalid('somos')}">
              </textarea>
            </mat-form-field>
            <div *ngIf="isFieldInvalid('somos')" class="text-danger">
              Este campo es requerido.
            </div>
    
            <div class="mb-3">
              <label for="imagenUsuario" class="form-label">Imagen tuya</label>
              <p style="margin-bottom: 0px;">*La imagen debe ser menor o igual a 550px de ancho y 650px de alto.</p>
              <input *ngIf="!imagenUsuario" 
                     style="height: 38px !important;" 
                     type="file" 
                     (change)="onFileSelected($event, 'imagenUsuario')"
                     class="form-control placeholder-text" 
                     required>
              <div *ngIf="imagenUsuario" class="mt-3">
                  <img [src]="imagenUsuario" alt="Imagen de usuario" class="img-fluid" style="width: 100px;" />
                  <button type="button" class="edu-btnTransition" (click)="removeImage('imagenUsuario')">Eliminar Imagen</button>
              </div>
            </div>
    
            <div class="mb-3">
              <label class="form-label">Recursos de regalo</label>
              <p style="margin-bottom: 0px;">*Las imágenes deben ser menor o igual a 550px de ancho y 550px de alto.</p>
              <input style="height: 38px !important;" 
                     type="file" 
                     (change)="onFileSelected($event, 'recursosRegalo')"
                     class="form-control placeholder-text" 
                     multiple>
              <div *ngIf="imagenesRecRegalo && imagenesRecRegalo.length > 0" class="mt-3">
                  <div *ngFor="let image of imagenesRecRegalo; let i = index">
                      <img [src]="image" alt="Recurso de regalo" class="img-fluid" style="width: 100px;" />
                      <button style="height: 46px;" type="button" class="edu-btnTransition" (click)="removeImageRespald2(i)">Eliminar Imagen</button>
                  </div>
              </div>
            </div>
    
    
            <div [formGroup]="form">
              <div formArrayName="preguntasRespuestas">
                <div *ngFor="let preguntaRespuesta of preguntasRespuestas.controls; let i = index" [formGroupName]="i" class="row align-items-center mb-2">
                  <div class="col-md-5">
                    <input style="margin: 0;" type="text" formControlName="pregunta" class="form-control" placeholder="Escribe tu pregunta">
                  </div>
                  <div class="col-md-2 text-center">
                    <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  </div>
                  <div class="col-md-5">
                    <input style="margin: 0;" type="text" formControlName="respuesta" class="form-control" placeholder="Escribe tu respuesta">
                  </div>
                  <div class="col-md-12 text-end mt-2">
                    <button type="button" class="btn btn-danger" (click)="removePreguntaRespuesta(i)">Eliminar</button>
                  </div>
                </div>
              </div>
              <button type="button" class="btn btn-primary mt-2" (click)="addPreguntaRespuesta()">Agregar Pregunta y Respuesta</button>
            </div>
            
    
            <div class="mb-3">
              <label for="deseo" class="form-label">Sube tu recurso</label><!--Checkar si este no es el del recurso-->
    
              <input style="margin: 0; height: 38px !important;" type="file" formControlName="deseo" (change)="onFileSelected($event,'recurso')"
                class="form-control placeholder-text" [ngClass]="{'is-invalid': isFieldInvalid('deseo')}" required>
              <div *ngIf="isFieldInvalid('deseo')" class="text-danger">
                Este campo es requerido.
              </div>
            </div>
    
            <mat-form-field appearance="outline" class="mb-3 w-100">
              <mat-label>Tipo de recurso</mat-label>
              <mat-select 
                [(value)]="tipoRecursoSeleccionado"
                (selectionChange)="onTipoRecursoChange($event)"
                required>
                <mat-option value="" disabled>Seleccione el tipo de recurso</mat-option>
                <mat-option *ngFor="let tipo of tiposDeRecursos" [value]="tipo">{{ tipo }}</mat-option>
              </mat-select>
              <mat-hint *ngIf="isFieldInvalid('tipoRecurso')" class="text-danger">
                Este campo es requerido.
              </mat-hint>
            </mat-form-field>
            
            <!-- Para el campo de archivo -->
            <div class="mb-3" *ngIf="tipoRecursoSeleccionado">
              <label for="archivoRecurso" class="form-label">Subir archivo</label>
              <input
                id="archivoRecurso"
                type="file"
                (change)="onFile($event)"
                class="form-control placeholder-text"
                required
              >
              
              <!-- Mostrar el archivo actual si existe -->
              <div *ngIf="archivoSeleccionado" class="mt-3">
                <p>Archivo actual: 
                  <a [href]="archivoSeleccionado" target="_blank">
                    Ver archivo
                  </a>
                </p>
              </div>
            </div>
    
            <div class="d-flex justify-content-center">
              <button  class="btn btn-primary"  (click)="generarLanding()">Generar Landing</button>
            </div>
            
          </form>
        </div>
      </div>
      <div class="col-md-8" *ngIf="!crearPlantilla">
        <div class="preview-sticky">
          <h5 class="mb-4 text-center text-dark">Esta es la vista previa de cómo se verá tu landing page.</h5> 
          <div class="bg-white">
          <div class="browser-window w-100">
            <div class="browser-header">
              <div class="buttons">
                <span class="button close"></span>
                <span class="button minimize"></span>
                <span class="button maximize"></span>
              </div>
              <div class="address-bar">
                <!-- <input type="text" disabled [value]="course.Slug" readonly> -->
              </div>
            </div>
            <div class="browser-content" ><!--class="browser-content"-->
              <ng-container [ngSwitch]="plantillaSeleccionada">
                <app-landing-curso 
                  *ngSwitchCase="'Landing1'"
                  [gancho]="gancho"
                  [micronicho]="micronicho"
                  [propuesta]="propuesta"
                  [deseo]="deseo"
                  [GatilloM]="GatilloM"
                  [somos]="somos"
                  [selectedImage]="selectedImage"
                  [imgObtendras]="imgObtendras"
                  [imagenUsuario]="imagenUsuario"
                  [imagenesRecRegalo]="imagenesRecRegalo"
                  [imagenesRespaldo]="imagenesRespaldo"
                  [FAQs]="FAQs"
                  [respuestas]="respuestas"
                  [color1]="color1"
                  [color2]="color2"
                  [logo]="logo"
                  [tipografia]="tipografiaSeleccionada">
                </app-landing-curso>
            
                <app-landing2
                  *ngSwitchCase="'Landing2'"
                  [gancho]="gancho"
                  [micronicho]="micronicho"
                  [propuesta]="propuesta"
                  [deseo]="deseo"
                  [GatilloM]="GatilloM"
                  [somos]="somos"
                  [selectedImage]="selectedImage"
                  [imgObtendras]="imgObtendras"
                  [imagenUsuario]="imagenUsuario"
                  [imagenesRecRegalo]="imagenesRecRegalo"
                  [imagenesRespaldo]="imagenesRespaldo"
                  [FAQs]="FAQs"
                  [respuestas]="respuestas"
                  [color1]="color1"
                  [color2]="color2"
                  [logo]="logo"
                  [tipografia]="tipografiaSeleccionada">
                </app-landing2>
              </ng-container>
    
              <ul>
                <li *ngFor="let resultado of resultados">
                  {{ resultado.name }}
                </li>
              </ul>
              <div *ngIf="imagenUrl">
                <img [src]="imagenUrl" alt="Imagen seleccionada" class="img-fluid" />
              </div>
              <p *ngIf="archivoSeleccionado">Archivo seleccionado: {{ archivoSeleccionado.name }}</p>
              <!-- <p>{{somos}}</p> -->
              <p>{{FAQs}}</p>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </mat-step>
  </mat-horizontal-stepper>
</div>

<!-- <div class="card-body ng-star-inserted">
 
  <div class="mb-8 stepper-container">
    <div class="stepper">
      <div class="stepper-step" [class.active]="pasoActual >= 0" [class.completed]="pasoActual > 0">
        <div class="step-number">
          1
        </div>
        <div class="step-title">
          Seleccionar Diseño
        </div>
      </div>

      <div class="progress-line" [class.active]="pasoActual >= 1">
        <div class="progress-bar" [style.width.%]="pasoActual >= 1 ? '100%' : '0%'"></div>
      </div>
  
      <div class="stepper-step" [class.active]="pasoActual >= 1" [class.completed]="pasoActual > 1">
        <div class="step-number">
          2
        </div>
        <div class="step-title">
          Completar Información
        </div>
      </div>
    </div>
  </div>


  <div [ngSwitch]="pasoActual">
    

    
  </div>
</div> -->
