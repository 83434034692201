<!-- students.component.html -->
<div class="students-container">
  <h3>Estudiantes Registrados</h3>
  
  <!-- Indicador de carga -->
  <div *ngIf="loading" class="loading">
    Cargando estudiantes...
  </div>
  
  <!-- Mensaje de error -->
  <div *ngIf="error" class="error-message">
    {{ error }}
  </div>
  
  <!-- Lista de estudiantes -->
  <div *ngIf="!loading && !error && students.length > 0" class="students-list">
    <!-- students.component.html -->
    <div class="table-template">
      <div style="text-align: justify;">
        <table class="table table-lg table-hover row-border hover">
          <thead class="thead-dark text-center">
          <tr>
            <th style="width: 10%;">ID</th>
            <th style="width: 20%;">Nombre</th>
            <th style="width: 25%;">Correo electrónico</th>
            <th style="width: 15%;">Teléfono</th>
          </tr>
          </thead>
          <tbody>
            <!-- Indicador de carga -->
            <tr *ngIf="loading">
              <td colspan="4" class="text-center">
              <span>Cargando estudiantes...</span>
              </td>
            </tr>
            
            <!-- Mensaje de error -->
            <tr *ngIf="error && !loading">
              <td colspan="4" class="text-center">
              <span class="error-message">{{ error }}</span>
              </td>
            </tr>
            
            <!-- Verifica si la lista de estudiantes está vacía -->
            <tr *ngIf="students.length === 0 && !loading && !error">
              <td colspan="4" class="text-center">
              <span>No hay estudiantes disponibles.</span>
              </td>
            </tr>
            
            <!-- Lista de estudiantes -->
            <tr *ngFor="let student of students; let i = index" style="text-align: center;">
              <td>{{ student.id }}</td>
              <td>{{ student.displayName }}</td>
              <td>{{ student.email }}</td>
              <td>
              <span *ngIf="student.phoneNumber && student.phoneNumber.trim() !== ''; else noPhone">
                  {{ student.phoneNumber }}
              </span>
              <ng-template #noPhone>
                <span>Sin número de teléfono</span>
              </ng-template>
              </td>
            </tr>
          </tbody>
        </table>  
      </div>
      <div class="pagination-controls">
        <button class="btn btn-primary" (click)="cambiarPagina(currentPage - 1)" [disabled]="currentPage === 1">
          <span class="material-icons">chevron_left</span>
        </button>
        <span class="page-number">{{ currentPage }}</span> 
        <button class="btn btn-primary" (click)="cambiarPagina(currentPage + 1)" [disabled]="currentPage * limit >= totalStudents">
          <span class="material-icons">chevron_right</span>
        </button>
      </div>
    </div>
  </div>
  
  <!-- Mensaje cuando no hay estudiantes -->
  <div *ngIf="!loading && !error && students.length === 0" class="no-students">
    No hay estudiantes registrados.
  </div>
</div>