import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { getHeaders } from '../utils/headers.functions';

const BASE_URL: string = environment.API_URL;
const httpOptions: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');



@Injectable({
    providedIn: 'root'
})
export class ProductsService {
    public uploadFile2(formData: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/uploadfile`, formData);
    }
    updateTopic(id: any, newtopic: { videoUrl: any; FileName: string; FileSize: String; Type: string; }) {
        const headers: HttpHeaders = getHeaders();
        return this.http.put<any>(`${BASE_URL}/altertopics/${id}`, newtopic, { headers });
    }
    
    postCampaign(body: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/campaigns`, body);
    }

    getCampaignDetails(id: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/campaign-details/${id}`);
    }

    putCampaign(id: any, body: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.put<any>(`${BASE_URL}/campaign-details/${id}`, body);
    }

    public currentUser: any;
    httpOption: any;

    uploadVideo(formData: FormData) {
        return this.http.put<any>(`${BASE_URL}/upload-video`, formData);
    }


    constructor(
        private http: HttpClient,
        private router: Router
    ) {
        this.currentUser = this.userValue;
    }

    public get userValue(): any {
        return JSON.parse(localStorage.getItem('currentUser') ?? '{}');
    }

    public uploadImg(formData: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/uploadfile`, formData, { headers });
    }

    setCoursePreview(uuid: string, id: number) {
        const headers: HttpHeaders = getHeaders();
        return this.http.put<any>(`${BASE_URL}/masteracc/${uuid}`, { productid: id }, { headers });
    }

    validateActivation(productId: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/validate-products/${productId}`, { headers });
    }

    public uploadFile(formData: any, productId: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/saveDocument/${productId}`, formData, { headers });
    }

    public uploadFileWithoutID(formData: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/uploadarchive`, formData, { headers });
    }



    public createFeature(feature: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/catalogs`, feature, { headers });
    }

    public updateFeature(id: number, feature: any): Observable<any> {
        const buildFeature = this.editFeature(feature);
        const updatedFeature = this.addBackendStructureFeature(buildFeature);
        const headers: HttpHeaders = getHeaders();
        return this.http.put<any>(`${BASE_URL}/catalog/${id}`, updatedFeature, { headers });
    }

    public getFeatures(): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/catalogs-data/${'Feature'}`, { headers });
    }

    public deleteFeature(id: number): Observable<any> {
        return this.http.delete<any>(`${BASE_URL}/catalog/${id}`);
    }



    public getCatalogs(): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/catalogs`, { headers });
    }

    public getCatalogsName(name): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/catalogs-data/${name}`, { headers });
    }

    public postCatalogs(body): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/catalogs/`, body, { headers });
    }

    public getValuestoCatalog(Catalog: string): Observable<any> {
        return this.http.get<any>(`${BASE_URL}/catalogs-data/${Catalog}`);
    }

    public getValue(id: string | number): Observable<any> {
        return this.http.get<any>(`${BASE_URL}/catalog/${id}`);
    }

    public getCatalogsAlias(): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/catalogs-alias`, { headers });
    }

    public getSpecifications(): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/catalogs-data/${'Specification'}`, { headers });
    }

    public getFamilies(): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/catalogs-data/${'Family'}`, { headers });
    }

    public getCategories(): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/catalogs-data/${'Category'}`, { headers });
    }

    public getCatalog(name: string): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/catalogs-data/${name}`, { headers });
    }

    public getSubCategories(): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/catalogs-data/${'Subcategory'}`, { headers });
    }

    public getCourses(): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/products?Family=${'Course'}`);
    }

    public getCourses2(search: string, id: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/products?Family=Course&search=${search}&user_id=${id}`, { headers });
    }

    public getCapacitations(): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/products?Family=${'Capacitation'}`);
    }

    public getWebinars(): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/products?Family=${'Webinar'}`);
    }
    public getEvents(): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/products?Family=${'Event'}`);
    }

    public deleteCourses(productId: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.delete<any>(`${BASE_URL}/products/${productId}`, { headers });
    }

    public getProduct(productId: string): Observable<any> {
        return this.http.get<any>(`${BASE_URL}/products/${productId}`);
    }

    public deleteModulos(studyprogramId: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.delete<any>(`${BASE_URL}/deactivate/${studyprogramId}`, { headers });
    }

    public deleteLecciones(topicId: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.delete<any>(`${BASE_URL}/deleteTopics/${topicId}`, { headers });
    }
    
    public getVideos(body: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/onboarding/getVideoByName`, body);
    }

    public changeStatusVideo(videoId: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.delete<any>(`${BASE_URL}/onboarding/finishVideo/${videoId}`);
    }

    public getProductsFiltered(family: string): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/products-filter?family=${family}`);
    }

    public createCourse(product: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/products`, product, { headers });
    }

    public createVideoteca(product: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/products`, product, { headers });
    }

    public getVideoteca(search: string,id: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/products?Family=${'Videoteca'}&user_id=${id}`);
    }

    public updateCourse(id: number, product: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.put<any>(`${BASE_URL}/products/${id}`, product, { headers });
    }

    public updateCourse2(id: number, product: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.put<any>(`${BASE_URL}/products`, product, { headers });
    }

    public deleteProduct(id: number): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.delete<Observable<any>>(`${BASE_URL}/products/${id}`, { headers });
    }

    postModules(body: any): Observable<any> {
        if (this.currentUser) { // Asegúrate de que `this.currentUser` contenga el token de autenticación
            const httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': this.currentUser.token, // Agrega 'Bearer' antes del token
                    'Content-Type': 'application/json'
                })
            };

            // Realiza la solicitud POST con los encabezados de autenticación
            return this.http.post<any>(`${BASE_URL}/studyprogram`, body, httpOptions);
        } else {
            // Manejo del caso en que `currentUser` es nulo
            console.error('Usuario no autenticado');
            return throwError('Usuario no autenticado');
        }
    }

    public postModule0(Property, Value, ProductId): Observable<any> {
        if (this.currentUser) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': this.currentUser.token
                })
            };
            // this.httpOption = httpOptions.set('Authorization', this.currentUser.token);
            return this.http.post<any>(`${BASE_URL}/info-product`, { Property, Value, ProductId }, httpOptions);
        }

    }

    uploadVideoModulo(formData: FormData): Observable<HttpEvent<any>> {
        if (this.currentUser) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': this.currentUser.token
                }),
                reportProgress: true,  // Para obtener el progreso de la subida
                observe: 'events' as const, // Aseguramos que observe es de tipo 'events'
                responseType: 'json' as const  // Para asegurarnos de que la respuesta sea JSON
            };
    
            return this.http.put<any>(`${BASE_URL}/upload-video`, formData, {
                headers: httpOptions.headers,
                reportProgress: httpOptions.reportProgress,
                observe: httpOptions.observe,
                responseType: httpOptions.responseType
            });
        }
    }       

    public uploadResource(formData: any): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/uploadfile`, formData, { headers });
    }

    public postResource(Property: string, Value: string, Commentary: string, ProductId: number, FileName: string, FileSize: number, Type: string): Observable<any> {
        if (this.currentUser) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': this.currentUser.token
                })
            };
            
            // Crear el cuerpo del POST con los nuevos campos
            const body = {
                Property,
                Value,
                Commentary,
                ProductId,
                FileName,  // Nombre del archivo
                FileSize,  // Tamaño del archivo
                Type   // Tipo/Extensión del archivo
            };

            // Verificar el cuerpo antes de enviar
            console.log('Body de la solicitud:', body);
            
            // Realizar el POST incluyendo los nuevos datos
            return this.http.post<any>(`${BASE_URL}/info-product`, body, httpOptions);
        }
    }
    

    public postContent(Property, Value, Commentary, ProductId): Observable<any> {
        if (this.currentUser) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': this.currentUser.token
                })
            };
            // this.httpOption = httpOptions.set('Authorization', this.currentUser.token);
            return this.http.post<any>(`${BASE_URL}/info-product`, { Property, Value, Commentary, ProductId }, httpOptions);
        }
    }

    public mapRequiredValues(product: any): any {
        //console.log(product);
        const productInfo: any = {};

        {
            productInfo.id = product.id;
            productInfo.Category = product.Category;
            productInfo.SKU = product.SKU;
            productInfo.Title = product.Title;
            productInfo.Description = product.Description;
            productInfo.UnitPrice = product.UnitPrice;
            productInfo.CurrencyCode = product.CurrencyCode;
            productInfo.ImgFileUrl = product.ImgFileUrl;
            productInfo.CourseModel = product.CourseModel;
            productInfo.Objective = product.Objective;
            productInfo.Introduction = product.Introduction;
            productInfo.Level = product.Level;
            productInfo.TotalHours = product.TotalHours;
            productInfo.Language = product.Language;
            productInfo.LanguageSubtitles = product.LanguageSubtitles;
            productInfo.AccessType = product.AccessType;
            productInfo.TargetMarket = product.TargetMarket;
            productInfo.FileUrl = product.FileUrl;
            productInfo.DiscountPrice = product.DiscountPrice;
            productInfo.StartDate = product.StartDate;
            productInfo.EndDate = product.EndDate;
            productInfo.Teacher = product.Teacher;
            productInfo.Percentage = product.Percentage;
            productInfo.Name = product.Name;
            productInfo.numberTopics = product.numberTopics;
            productInfo.courseDuration = product.courseDuration;
            productInfo.SubCategory = product.SubCategory;
            productInfo.teacher_name = product.teacher_name;
        }

        return productInfo;
    }

    private editProduct(product: any): any {
        // console.log(product);
        const editProduct = {
            Title: product.Title,
            Family: product.Family,
            Category: product.Category,
            SubCategory: product.SubCategory,
            Brand: product.Brand,
            SKU: product.SKU,
            Model: product.Model,
            Description: product.Description,
            UnitPrice: product.UnitPrice,
            DiscountPrice: product.DiscountPrice,
            CurrencyCode: product.CurrencyCode,
        };
        return editProduct;
    }

    private addBackendStructure(product: any) {
        return {
            Title: product.Title,
            Family: product.Family,
            Category: product.Category,
            SubCategory: product.SubCategory,
            Brand: product.Brand,
            SKU: product.SKU,
            Model: product.Model,
            Description: product.Description,
            UnitPrice: product.UnitPrice,
            DiscountPrice: product.DiscountPrice,
            CurrencyCode: product.CurrencyCode,
            ProductInfo: product.ProductInfo
        };
    }

    private editFeature(feature: any): any {
        const editFeature = {
            CatalogName: feature.CatalogName,
            ValueAlpha: feature.ValueAlpha,
        };
        return editFeature;
    }

    private addBackendStructureFeature(feature: any) {
        return {
            CatalogName: feature.CatalogName,
            ValueAlpha: feature.ValueAlpha
        };
    }

    public inicializarCurso(body: any): Observable<any> {
        if (this.currentUser) {
            const userid = this.currentUser.id;
            body.userid = userid;
            const httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': this.currentUser.token
                })
            };
            // console.log(httpOptions);
            // this.httpOption = httpOptions.set('Authorization', this.currentUser.token);
            // return this.http.post<any>(BASE_URL + '/init-product', { headers: this.httpOption });
            return this.http.post<any>(`${BASE_URL}/init-product`, body, httpOptions);
        }
        // const headers: HttpHeaders = getHeaders()
    }

    public guardarRespuestaGPT(Property, Value, ProductId): Observable<any> {
        if (this.currentUser) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': this.currentUser.token
                })
            };
            // this.httpOption = httpOptions.set('Authorization', this.currentUser.token);
            return this.http.post<any>(`${BASE_URL}/info-product`, { Property, Value, ProductId }, httpOptions);
        }

    }
    public editarRespuestaGPT(id, Property, Value, ProductId): Observable<any> {
        if (this.currentUser) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': this.currentUser.token
                })
            };
            // this.httpOption = httpOptions.set('Authorization', this.currentUser.token);
            return this.http.put<any>(`${BASE_URL}/info-product`, { id, Property, Value, ProductId }, httpOptions);
        }

    }

    public porcentajeCracionCurso(id, CoursePct): Observable<any> {
        if (this.currentUser) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': this.currentUser.token
                })
            }
            return this.http.put<any>(`${BASE_URL}/products`, { id, CoursePct }, httpOptions);
        }

    }
    public enviarloanding(body): Observable<any> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/v2/info-product`, body, { headers });
    }

    public uploadVideoVideoteca(formData: FormData): Observable<HttpEvent<any>> {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/uploadfile`, formData, {
          headers,
          reportProgress: true, // Habilitar el seguimiento del progreso
          observe: 'events',    // Observar eventos HTTP (incluido el progreso)
        });
    }      
    
    public setProductInfo(body: any){
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/info-product`, body, { headers });
    }
    // public  updateProfile(body: any): Observable<any> {
    //     const headers: HttpHeaders = getHeaders();
    //     return this.http.post<any>(`${BASE_URL}/user-profile`, body, { headers });
    // }
    
    getInstructor(limit: number, offset: number, q: string): Observable<any> {
        /* const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/teachers?limit=${limit}&offset=${offset}&q=${q}`, { headers }); */
        if (this.currentUser) {
            const httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': this.currentUser.token
                })
            };
            // this.httpOption = httpOptions.set('Authorization', this.currentUser.token);
            return this.http.get<any>(`${BASE_URL}/teachers?limit=${limit}&offset=${offset}&q=${q}`, httpOptions);
        }
    }

    listByRole(limit: number, offset: number, q: string, role: string) {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/users-by-role/${role}?limit=${limit}&offset=${offset}&q=${q}`, { headers });
    }

    public addCollaborator(body: any) {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/collaborators/addcollaborator`, body, { headers });
    }
   
    public disableCollaborator(body: any) {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/collaborators/disableCollaborator`, body, { headers });
    }
    
    public enableCollaborator(body: any) {
        const headers: HttpHeaders = getHeaders();
        return this.http.post<any>(`${BASE_URL}/collaborators/enableCollaborator`, body, { headers });
    }

    public listTeacherRelatedWith(userId: any) {
        const headers: HttpHeaders = getHeaders();
        return this.http.get<any>(`${BASE_URL}/collaborators/listTeachersRelatedWith/${userId}`, { headers });
    }
    
    public listCollabsRelatedWith(userId: any, limit: number, offset: number, search: string) {
        const headers: HttpHeaders = getHeaders();
    
        // Crear los parámetros
        const params = new HttpParams()
            .set('limit', limit.toString())
            .set('offset', offset.toString())
            .set('search', search);
    
        // Realizar la solicitud GET con los parámetros
        return this.http.get<any>(`${BASE_URL}/collaborators/listCollabsRelatedWith/${userId}`, { headers, params });
    }    

    setLandingLayout(data: { id: number, landinglayout: string }) {
        return this.http.post(`${BASE_URL}/setLandingLayout`, data);
    }
}
