import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { getHeaders } from '../utils/headers.functions';
let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', }), responseType: 'text' as 'json' };

const apiUrl = environment.API_URL;

@Injectable({ providedIn: 'root' })

export class APIService {

    private userSubject: BehaviorSubject<any>;
    public user: Observable<any>;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.userSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
    }

    public get userValue(): any {
        return this.userSubject.value;
    }

    login(body: any) {
        return this.http.post<any>(`${apiUrl}/auth/adminlogin`, body);
    }

    logout() {
        localStorage.removeItem('user');
        this.userSubject.next(null);
        this.router.navigate(['/pages/login']);
    }

    register(user: any) {
        return this.http.post<any>(`/servicios.svc/Registrar`, user, httpOptions);
    }

    getAll() {
        return this.http.get<any[]>(`/users`);
    }

    getById(id: string) {
        const headers = getHeaders()
        return this.http.get<any>(`${apiUrl}/user/${id}`, { headers });
    }

    //
    /* updateResource(data: any){
         const token =
         localStorage.getItem('token');
     }
 
     const Headers = new HttpHeaders({'Authorization': 'Bearer ${token}'
 
     });
 ***/


    update(id, params) {
        return this.http.put(`/users/${id}`, params)
            .pipe(map(x => {
                if (id == this.userValue) {
                    const user = { ...this.userValue, ...params };
                    localStorage.setItem('user', JSON.stringify(user));

                    this.userSubject.next(user);
                }
                return x;
            }));

        //  return this.http.put(this.apiUrl, data, {Headers});
    }
}

