import { Component } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { FooterComponent } from 'src/app/shared/components/footer/footer.component';
import { LandingsService } from 'src/app/shared/service/landings.service';

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [MatFormFieldModule,MatInputModule,ReactiveFormsModule,RouterModule],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss'
})
export class LandingComponent {
  slug: any;
  datosCurso: { micronicho: any; deseo: any; problema: any; descripcion: any; };
  recurso: { tipoRecurso: any; recurso: any; };
  userData: { id: any; name: any; carrera: any; biografia: any; imagen: any; };
  form: any;
  
  constructor(private fb: FormBuilder,private route: ActivatedRoute,private landingService: LandingsService) {
    this.slug = this.route.snapshot.paramMap.get('Slug');
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit(){

    this.landingService.getLandingPage(this.slug).subscribe((data)=>{
      console.log(data);
      this.datosCurso = {
        micronicho: data.ladingData[0].Value,
        deseo: data.ladingData[1].Value,
        problema: data.ladingData[2].Value,
        descripcion: data.ladingData[5]
      }
      this.recurso = {
        tipoRecurso: data.ladingData[7].Value,
        recurso: data.ladingData[6].Value
      }
      
      this.userData = {
        id: data.userData.id,
        name: data.userData.displayName,
        carrera : data.userData.carrera,
        biografia: data.userData.biography,
        imagen: data.userData.profileFileUrl
      }
      
     
    })
  }

  onSubmit(){

  }
}
