import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CreacionLandingService } from 'src/app/shared/service/creacion-landing.service';



@Component({
  selector: 'app-landing-curso',
  standalone: true,
  imports: [CommonModule,MatExpansionModule],
  templateUrl: './landing-curso.component.html',
  styleUrl: './landing-curso.component.scss'
})
export class LandingCursoComponent implements OnInit {
  @Input() previewMode: boolean = false;
  @Input() plantilla: any;
  @Input() gancho: string;
  @Input() propuesta: string;
  @Input() deseo: string;
  @Input() somos:string;
  @Input() selectedImage:any;
  @Input() imgObtendras:any;
  @Input() imagenUsuario:any;
  // @Input() queObtendras:  { name: string }[] = [];
  @Input() micronicho: string;
  @Input() GatilloM: string;
  @Input() FAQs: string;
  @Input() respuestas: string;
  @Input() imagenesRespaldo: [];
  @Input() imagenesRecRegalo: [];
  @Input() color1: any;
  @Input() color2: any;
  @Input() logo: any;
  @Input() tipografia: any;
  queObtendras: any;
  faqsArray: string[] = [];
  respuestasArray: string[] = [];

  public resultados:any [] = [];

  isHovered = false; 
  isHoveredPresentación = false;
  isHoveredContacto = false;
  isHoveredAprovecha = false;
  isHoveredWhatsApp = false;
  countdown: string = '';
  activoIndex: any;
  imagenesRespaldan: SafeUrl;
  recursos: any;
  preguntas: any;
  deseos: any;
  recursosRegalo: SafeUrl;
  preguntasRespuestas: any[];
  constructor(private sanitizer: DomSanitizer,private creacionLandingService: CreacionLandingService) { }

  ngOnInit(): void {

    this.faqsArray = this.FAQs ? this.FAQs.split(',') : [];
    this.respuestasArray = this.respuestas ? this.respuestas.split(',') : [];
    this.creacionLandingService.queObtendras$.subscribe(data => {
      this.queObtendras = data;
    });

    // this.creacionLandingService.getImages().subscribe(data => {
    //   console.log(data);
    //   this.imagenesRespaldan = data;
    //   console.log(this.imagenesRespaldan)
    // })

    this.creacionLandingService.getImages().subscribe(data => {
      // this.imagenesRespaldan.next(data);
      // console.log(this.imagenesRespaldan)
      // console.log(data);
      this.imagenesRespaldan = data;
    })

    this.creacionLandingService.getImages2().subscribe(data => {
      // console.log(data);
      this.recursosRegalo = data;
    })

    this.creacionLandingService.getPreguntasRespuestas().subscribe(data => {
      this.preguntasRespuestas = data;
      // console.log(this.preguntasRespuestas);
    })
  }



  // ngOnInit(): void {
  //   console.log(this.queObtendras);
  // }

  // bloqueSeleccionadoU(i: any, event: any) {

  //   if (this.activoIndex === i) {
  //     event.currentTarget.classList.remove('accordion-button-active');
  //     this.activoIndex = null; // Resetear el índice activo
  //   } else {
  //     // Si hay un índice activo previo, encuentra el botón correspondiente y remueve la clase
  //     if (this.activoIndex !== null) {
  //       const prevButton = document.querySelector(`[data-bs-target='#collapseOne${this.activoIndex}']`);
  //       if (prevButton) {
  //         prevButton.classList.remove('accordion-button-active');
  //       }
  //     }
  //     if (event.currentTarget.getAttribute('aria-expanded') === 'true') {
  //       event.currentTarget.classList.add('accordion-button-active');
  //       this.activoIndex = i
  //     } else {
  //       event.currentTarget.classList.remove('accordion-button-active');
  //     }
  //   }

    

  // }


}
