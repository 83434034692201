// students.component.ts
import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/shared/service/dashboard.service';
import { APIService } from 'src/app/shared/service/apiService.service';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss']
})
export class StudentsComponent implements OnInit {
  students: any[] = [];
  paginatedStudents: any[] = [];
  loading: boolean = false;
  error: string | null = null;

  profile: any;
  Id: any;

  // Paginación
  currentPage: number = 1;
  limit: number = 10; // Número de estudiantes por página
  totalStudents: number = 0;

  constructor(private dashboardservice: DashboardService, private apiService: APIService) { }

  ngOnInit(): void {
    this.loading = true;
    
    try {
      const user = JSON.parse(localStorage.getItem('currentUser') || '{}');
      
      if (!user || !user.id) {
        this.error = 'No se encontró información del usuario.';
        this.loading = false;
        return;
      }

      this.apiService.getById(user.id).subscribe({
        next: (data: any) => {
          this.profile = data;
          this.Id = this.profile.id;
          console.log('Info', this.Id);
          
          this.loadStudents();
        },
        error: (err) => {
          console.error('Error al obtener perfil:', err);
          this.error = 'No se pudo obtener la información del perfil.';
          this.loading = false;
        }
      });
    } catch (error) {
      console.error('Error al procesar datos del usuario:', error);
      this.error = 'Error al procesar datos del usuario.';
      this.loading = false;
    }
  }

  loadStudents(): void {
    if (!this.Id) {
      console.error('ID no disponible para cargar estudiantes');
      this.error = 'No se puede cargar estudiantes: ID no disponible.';
      this.loading = false;
      return;
    }
    
    this.loading = true;
    this.error = null;
    
    this.dashboardservice.getStudentsRegistered(this.Id)
      .subscribe({
        next: (data) => {
          this.students = data; // Asignamos los datos de la API
          this.totalStudents = data.length; // Total de estudiantes
          this.updatePaginatedStudents(); // Actualizamos los estudiantes paginados
          this.loading = false;
        },
        error: (err) => {
          console.error('Error al cargar estudiantes:', err);
          this.error = 'No se pudieron cargar los estudiantes. Por favor, intenta de nuevo.';
          this.loading = false;
        }
      });
  }

  // Método para cambiar de página
  cambiarPagina(page: number): void {
    if (page < 1 || (page > 1 && page * this.limit > this.totalStudents)) {
      return;
    }
    this.currentPage = page;
    this.updatePaginatedStudents();
  }

  // Método para actualizar los estudiantes paginados
  updatePaginatedStudents(): void {
    const startIndex = (this.currentPage - 1) * this.limit;
    const endIndex = startIndex + this.limit;
    this.paginatedStudents = this.students.slice(startIndex, endIndex);
  }
}