<div class="page-wrapper">
    <div class="authentication-box">
        <div class="container">
            <div class="row">
                <div class="col-md-5 card-left hub-center">
                    <img src="../../../../assets/images/logo1.png" style="width:100%" alt="">
                    <img src="../../../../assets/images/backoffice.png" style="width:100%" alt="">
                </div>
                <div class="col-md-7 card-right">
                    <div class="card tab2-card">
                        <div class="card-body">
                            <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs">
                                <li [ngbNavItem]="1">
                                    <a ngbNavLink>Iniciar sesión</a>
                                    <ng-template ngbNavContent>
                                        <div class="tab-pane fade active show" id="account" role="tabpanel"
                                            aria-labelledby="account-tab">
                                            <form [formGroup]="loginForm"
                                                class="form-horizontal auth-form needs-validation">
                                                <div class="form-group">
                                                    <input
                                                        [ngClass]="{'error': submittedLogin && loginForm.controls.email.hasError('required') }"
                                                        formControlName="email" type="email" class="form-control"
                                                        placeholder="Correo electrónico" id="exampleInputEmail1">
                                                </div>
                                                <div class="form-group">
                                                    <input
                                                        [ngClass]="{'error': submittedLogin && loginForm.controls.password.hasError('required') }"
                                                        formControlName="password" type="password" class="form-control"
                                                        placeholder="Contraseña">
                                                </div>
                                                <div class="form-terms">
                                                    <div
                                                        class="custom-control custom-checkbox d-flex align-items-center flex-wrap">
                                                        <!-- <input type="checkbox" class="custom-control-input me-2"
                                                            id="customControlAutosizing">
                                                        <label class="custom-control-label"
                                                            for="customControlAutosizing">Remember
                                                            me</label> -->
                                                        <a href="javascript:void(0)"
                                                            class="btn btn-default forgot-pass ms-auto">¿Olvidaste tu
                                                            contraseña?</a>
                                                    </div>
                                                </div>
                                                <div class="form-button">
                                                    <button class="btn btn-primary" (click)="onSubmit()" type="submit">
                                                        Iniciar sesión</button>
                                                </div>
                                                <!-- <div class="form-footer">
                                                    <span>Or Login up with social platforms</span>
                                                    <ul class="social">
                                                        <li><a class="icon-facebook" href="javascript:void(0)"></a></li>
                                                        <li><a class="icon-twitter" href="javascript:void(0)"></a></li>
                                                        <li><a class="icon-instagram" href="javascript:void(0)"></a>
                                                        </li>
                                                        <li><a class="icon-pinterest" href="javascript:void(0)"></a>
                                                        </li>
                                                    </ul>
                                                </div> -->
                                            </form>
                                        </div>
                                    </ng-template>
                                </li>
                            </ul>
                            <div [ngbNavOutlet]="nav" class="mt-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>