import { Component, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgIf, NgClass } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ProductsService } from 'src/app/shared/service/products.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { map, Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/cdk/stepper';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import * as Notiflix from 'notiflix';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

// import { GrapesjsComponent } from "./grapesjs/grapesjs.component";
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { LandingCursoComponent } from '../landing-curso/landing-curso.component';
import { CreacionLandingService } from 'src/app/shared/service/creacion-landing.service';
import { ReadStream } from 'fs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MatExpansionModule } from '@angular/material/expansion';
import { LandingsService } from 'src/app/shared/service/landings.service';
import { forkJoin } from 'rxjs';
import { MatSelectModule } from '@angular/material/select';
import { Landing2Component } from "../landing-curso/landing2/landing2.component";

export interface resultados {
  name: string;
}

export interface nombreCursos {
  name: string;
}

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [ReactiveFormsModule, MatExpansionModule, MatFormFieldModule, MatInputModule, CommonModule, MatStepperModule, MatFormFieldModule,
    MatInputModule,
    MatChipsModule, MatIconModule, LandingCursoComponent, MatSelectModule, Landing2Component],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class LoadingComponent implements OnInit {
  tipografiaSeleccionada: string = '';
  fuentes: string[] = [
    'Arial',
    'Tahoma',
    'Times New Roman',
    'Courier New',
    'Verdana',
    'Georgia',
    'Lucida Console',
    'Garamond',
    'Comic Sans MS',
    'Trebuchet MS'
  ];

  infoForm: FormGroup;
  productInfo: any;
  formSubmitted = false;
  tiposDeRecursos: string[] = ['Video', 'Ebook', 'Documento'];
  // tipoRecursoSeleccionado: string = '';
  selectedFile: File | null = null;
  selectedRecursoFile: File | null = null;
  selectedProductId: number | null = null;  // Aquí almacenamos el ProductId
  selectedStep = 0;
  addOnBlur = true;
  urls = [];
  stepperOrientation: Observable<StepperOrientation>;
  @ViewChild(MatStepper) stepper: MatStepper;
  crearPlantilla: boolean;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  public resultados: any[] = [];

  public deseo: any; // Almacena el archivo
  public imagenUrl: string | ArrayBuffer | null = null; // Almacena la URL de la imagen
  public tipoRecursoSeleccionado: any = null; // Almacena el tipo de recurso seleccionado
  public archivoSeleccionado: any; // Almacena el archivo seleccionado
  queObtendras: any[] = [];
  preguntasFrecuentes: any[] = [];

  color1: any;
  color2: any;
  logo: any;
  selectedImage: any;
  imgObtendras: any;
  imagenUsuario: any;
  multipleImages: any;
  trackByFn: any;
  quienNosResplada: any;
  selectedImages: any;
  imagenesRespaldo: any;
  respuestas: any;
  respaldo: any;
  respaldoImages: File[] = [];
  imagenesRecRegalo: any;
  formPreguntas: any;
  form: any;
  preguntaRespuestaForm: any;

  plantillas = [
    {
      id: 1,
      nombre: "Plantilla Moderna",
      preview: true,
      estilo: {
        header: "bg-blue-500",
        layout: "moderna"
      }
    },
    {
      id: 2,
      nombre: "Plantilla Clásica",
      preview: true,
      estilo: {
        header: "bg-green-500",
        layout: "clasica"
      }
    },
  ];

  plantillaSeleccionada: string = '';
  pasoActual = 0;

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isEditing = false;
  landingSlug: string;
  constructor(
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private ProductsService: ProductsService,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private creacionLandingService: CreacionLandingService,
    private landingsService: LandingsService
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));

    this.infoForm = this.fb.group({
      gancho: ['', Validators.required],
      micronicho: ['', Validators.required],
      propuesta: ['', Validators.required],
      deseo: ['', Validators.required],
      imagen: ['', Validators.required],
      imgObtendras: ['', Validators.required],
      imagenUsuario: ['', Validators.required],
      GatilloM: ['', Validators.required],
      contador: ['', Validators.required],
      resultadoCurso: ['', Validators.required],
      quienNosResplada: ['', Validators.required],
      recursosObsequio: ['', Validators.required],
      respuestas: ['', Validators.required],
      respaldo: ['', Validators.required],
      recursosRegalo: ['', Validators.required],
      imagenesRecRegalo: ['', Validators.required],
      // deseo: ['', Validators.required],
      // problema: ['', Validators.required],
      // descripcion: ['', Validators.required],
      // imagen: ['', Validators.required],
      tipoRecurso: ['', Validators.required],
      recurso: ['', Validators.required],
      somos: ['', Validators.required],
      FAQs: ['', Validators.required],
      quienNosRespalda: ['', Validators.required],
      color1: ['', Validators.required],
      color2: ['', Validators.required],
      fuentes: ['', Validators.required],
      logo: ['', Validators.required],
      tipografia: ['', Validators.required],
    });

    this.form = this.fb.group({
      preguntasRespuestas: this.fb.array([])
    });
  }
  gancho: any;
  resourceUrl: any;
  micronicho: any;
  problema: any;
  descripcion: any;
  propuesta: any;
  GatilloM: any;
  contador: any;
  resultadoCurso: any;
  somos: any;
  FAQs: any;
  ngOnInit(): void {
    // Capturamos el id del curso desde la URL
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id) {
        this.selectedProductId = Number(id);  // Asignamos el ProductId seleccionado
        // Obtener el slug del curso
        this.obtenerSlugCurso(this.selectedProductId.toString());
      }
    });

    this.preguntasRespuestas.valueChanges.subscribe(changes => {
      // console.log('Preguntas y Respuestas cambiaron:', changes);
      this.creacionLandingService.setPreguntasRespuestas(changes);
    });


  }

  obtenerSlugCurso(productId: string): void {
    Notiflix.Loading.circle('Obteniendo información del curso...');
  
    this.landingsService.getProduct(productId).subscribe({
      next: (response) => {
        console.log('Respuesta de getProduct:', response);
        
        if (response && response.Slug) {
          this.landingSlug = response.Slug;
          console.log('Slug obtenido:', this.landingSlug);
          // Verificar si ya existen datos para este slug
          this.verificarDatosLanding(this.landingSlug);
        } else {
          console.warn('No se pudo obtener el slug del curso');
          Notiflix.Loading.remove();
          Notiflix.Notify.warning('No se pudo obtener el slug del curso');
        }
      },
      error: (error) => {
        console.error('Error al obtener información del curso:', error);
        Notiflix.Loading.remove();
        Notiflix.Notify.failure('Error al obtener información del curso');
      }
    });
  }

  verificarDatosLanding(slug: string): void {
    console.log('Verificando datos para slug:', slug);
    
    // Asegúrate de que el nombre del método sea correcto según tu servicio
    this.landingsService.getLandingPage(slug).subscribe({
      next: (response) => {
        console.log('Respuesta de getLandinPage:', response);
        
        if (response && response.ladingData && response.ladingData.length > 0) {
          console.log('Datos de landing encontrados, cargando...');
          this.isEditing = true;
          this.loadExistingLandingData(response);
        } else {
          console.log('No se encontraron datos para esta landing page');
          this.isEditing = false;
          Notiflix.Loading.remove();
          Notiflix.Notify.info('No hay datos previos para esta landing page');
        }
      },
      error: (error) => {
        console.error('Error detallado al verificar datos de landing:', error);
        this.isEditing = false;
        Notiflix.Loading.remove();
        Notiflix.Notify.warning('No se pudieron verificar datos previos');
      }
    });
  }

  // En loading.component.ts
  loadExistingLandingData(response: any): void {
    Notiflix.Loading.circle('Cargando datos de la landing page...');
  
    try {
      console.log('Procesando datos de landing:', response);
      
      if (!response || !response.ladingData || !Array.isArray(response.ladingData)) {
        throw new Error('Estructura de datos inválida');
      }
      
      const landingData = response.ladingData;
  
      // Mapear los datos al formulario y propiedades
      this.gancho = this.getPropertyValue(landingData, 'Landing-Gancho');
      this.propuesta = this.getPropertyValue(landingData, 'Landing-Propuesta');
      this.deseo = this.getPropertyValue(landingData, 'Landing-Deseo');
      this.selectedImage = this.getPropertyValue(landingData, 'Landing-Imagen');
      this.imgObtendras = this.getPropertyValue(landingData, 'Landing-ImgObtendras');
      this.imagenUsuario = this.getPropertyValue(landingData, 'Landing-ImagenUsuario');
      this.somos = this.getPropertyValue(landingData, 'Landing-Somos');
      this.color1 = this.getPropertyValue(landingData, 'Landing-Color1') || '#ffff';
      this.color2 = this.getPropertyValue(landingData, 'Landing-Color2') || '#0000';
      this.logo = this.getPropertyValue(landingData, 'Landing-Logo');
      this.tipografiaSeleccionada = this.getPropertyValue(landingData, 'Landing-Tipografia');
      this.plantillaSeleccionada = response.Course?.landinglayout || '';
      
      // Obtener el tipo de recurso y el archivo
      this.tipoRecursoSeleccionado = this.getPropertyValue(landingData, 'Landing-Tipo');
      this.archivoSeleccionado = this.getPropertyValue(landingData, 'Landing-Archivo');
  
      // Modificar cómo se cargan los datos de queObtendras
      const queObtendras = this.getPropertyValue(landingData, 'Landing-QueObtendras');
      if (queObtendras) {
        // Asegurarse de que cada elemento tiene el formato correcto
        this.queObtendras = queObtendras.split(',')
          .map(item => ({ name: item.trim() }))
          .filter(item => item.name); // Filtrar elementos vacíos
        
        // Actualizar el servicio con los datos cargados
        this.creacionLandingService.updateQueObtendras(this.queObtendras);
      }
  
      // Cargar imágenes de respaldo
      const respaldo = this.getPropertyValue(landingData, 'Landing-Respaldo');
      if (respaldo && respaldo !== 'Sin imágenes de respaldo') {
        this.imagenesRespaldo = respaldo.split(',');
      } else {
        this.imagenesRespaldo = [];
      }
  
      // Cargar recursos de regalo
      const recursos = this.getPropertyValue(landingData, 'Landing-Recursos');
      if (recursos && recursos !== 'Sin recursos de regalo') {
        this.imagenesRecRegalo = recursos.split(',');
      } else {
        this.imagenesRecRegalo = [];
      }
  
      // Cargar preguntas y respuestas
      const preguntas = this.getPropertyValue(landingData, 'Landing-Pregunta')?.split('-') || [];
      const respuestas = this.getPropertyValue(landingData, 'Landing-Respuesta')?.split('-') || [];
      
      // Limpiar el FormArray existente
      while (this.preguntasRespuestas.length) {
        this.preguntasRespuestas.removeAt(0);
      }
      
      // Añadir cada par de pregunta/respuesta
      for (let i = 0; i < preguntas.length; i++) {
        const preguntaForm = this.fb.group({
          pregunta: [preguntas[i] || ''],
          respuesta: [respuestas[i] || '']
        });
        this.preguntasRespuestas.push(preguntaForm);
      }
  
      // Actualizar el formulario SOLO con campos que no son de tipo file
      const formControls = {
        gancho: this.gancho,
        propuesta: this.propuesta,
        deseo: this.deseo,
        somos: this.somos,
        color1: this.color1,
        color2: this.color2,
        tipografia: this.tipografiaSeleccionada,
        tipoRecurso: this.tipoRecursoSeleccionado
      };
  
      // Actualizar solo los controles que no son de tipo file
      Object.keys(formControls).forEach(key => {
        if (this.infoForm.contains(key) && !this.isFileInput(key)) {
          this.infoForm.get(key)?.setValue(formControls[key], { emitEvent: false });
        }
      });
  
      Notiflix.Loading.remove();
      Notiflix.Notify.success('Datos cargados correctamente');
    } catch (error) {
      console.error('Error detallado al procesar los datos:', error);
      Notiflix.Loading.remove();
      Notiflix.Notify.failure('Error al cargar los datos: ' + (error.message || 'Error desconocido'));
    }
  }
  
  // Método auxiliar para verificar si un control es de tipo file
  private isFileInput(controlName: string): boolean {
    const fileInputs = ['logo', 'imagen', 'imgObtendras', 'imagenUsuario', 'deseo', 'imagenesRecRegalo'];
    return fileInputs.includes(controlName);
  }
  
  private getPropertyValue(data: any[], property: string): string | null {
    const item = data.find(d => d.Property === property);
    return item ? item.Value : null;
  }

  saveProduct() {

    const objetive = this.resultados.map((t: any) => { return t.name })
  }

  get preguntasRespuestas(): FormArray {
    return this.form.get('preguntasRespuestas') as FormArray;
  }

  addResultado(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.resultados.push({ name: value.trim() });
    }

    if (input) {
      input.value = '';
    }
  }

  removeResultado(resultado: string): void {
    const index = this.resultados.indexOf(resultado);

    if (index >= 0) {
      this.resultados.splice(index, 1); // Elimina el resultado del arreglo
    }
  }
  removeImageRespald(i: number) {
    console.log(i);
    this.imagenesRespaldo.splice(i, 1);
  }

  removeImageRespald2(i: number) {
    console.log(i);
    this.imagenesRecRegalo.splice(i, 1);
  }

  seleccionarPlantilla(plantilla: string) {
    console.log('Seleccionando plantilla:', plantilla);
    this.plantillaSeleccionada = plantilla;
    // Verifica que se haya asignado correctamente
    console.log('Plantilla asignada:', this.plantillaSeleccionada);
  }

  guardarLayoutSeleccionado() {
    if (this.plantillaSeleccionada && this.selectedProductId) {
      const data = {
        id: this.selectedProductId,
        landinglayout: this.plantillaSeleccionada
      };
      
      console.log('Datos a enviar:', data);
      
      this.ProductsService.setLandingLayout(data).subscribe({
        next: (response: any) => {
          console.log('Respuesta del servidor:', response);
          Notiflix.Notify.success(this.isEditing ? 'Plantilla actualizada correctamente' : 'Plantilla guardada correctamente');
          this.stepper.next();
        },
        error: (error) => {
          console.error('Error al guardar el layout:', error);
          Notiflix.Notify.failure('Error al guardar la plantilla');
        }
      });
    } else {
      Notiflix.Notify.warning('Por favor selecciona una plantilla');
    }
  }

  siguientePaso() {
    if (this.pasoActual === 0 && !this.plantillaSeleccionada) {
      Notiflix.Notify.warning('Por favor selecciona una plantilla');
      return;
    }
    this.pasoActual++;
  }

  pasoAnterior() {
    this.pasoActual--;
  }
  
  onFileSelected(event: any, type: string): void {
    const file = event.target.files[0];
    let maxWidth = 0;
    let maxHeight = 0;
  
    // Define las dimensiones permitidas según el tipo de imagen
    switch (type) {
      case 'imagenCurso':
        maxWidth = 550;
        maxHeight = 550;
        break;
      case 'imgObtendras':
        maxWidth = 550;
        maxHeight = 550;
        break;
      case 'imagenUsuario':
        maxWidth = 550;
        maxHeight = 650;
        break;
      case 'respaldo':
        maxWidth = 550;
        maxHeight = 550;
        break;
      case 'logo':
        maxWidth = 550;
        maxHeight = 180;
        break;
      case 'recursosRegalo':
        maxWidth = 550;
        maxHeight = 550;
        break;
      default:
        Swal.fire({
          icon: 'warning',
          title: 'Tipo de imagen no reconocido',
          text: 'Por favor, sube una imagen en un formato compatible.',
          confirmButtonText: 'Aceptar',
        });
        return;
    }
  
    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
  
      img.onload = () => {
        if (img.width <= maxWidth && img.height <= maxHeight) {
          // Dimensiones válidas, procede con la subida
          const formData = new FormData();
          formData.append('file', file);
          formData.append('type', 'images');
  
          this.landingsService.uploadImg(formData).subscribe(
            (response) => {
              console.log('Respuesta del servidor:', response);
              const imageUrl = response.ImageUrl;
  
              // Asigna la URL de imagen al campo correspondiente
              if (type === 'imagenCurso') {
                this.selectedImage = imageUrl;
              } else if (type === 'imgObtendras') {
                this.imgObtendras = imageUrl;
              } else if (type === 'imagenUsuario') {
                this.imagenUsuario = imageUrl;
              } else if (type === 'respaldo') {
                if (!this.imagenesRespaldo) {
                  this.imagenesRespaldo = [];
                }
                this.imagenesRespaldo.push(imageUrl);
              } else if (type === 'logo') {
                this.logo = imageUrl;
              } else if (type === 'recursosRegalo') {
                if (!this.imagenesRecRegalo) {
                  this.imagenesRecRegalo = [];
                }
                this.imagenesRecRegalo.push(imageUrl);
              }
            },
            (error) => {
              console.error('Error al subir la imagen:', error);
            }
          );
        } else {
          // Dimensiones no válidas, muestra un mensaje de error
          Swal.fire({
            icon: 'error',
            title: 'Dimensiones no válidas',
            text: `La imagen debe tener un ancho máximo de ${maxWidth}px y un alto máximo de ${maxHeight}px.`,
            confirmButtonText: 'Aceptar',
          });
          event.target.value = ''; // Limpiar el input de archivo
        }
  
        // Liberar la URL creada para la imagen
        URL.revokeObjectURL(img.src);
      };
    } else {
      console.warn('No se seleccionó ningún archivo.');
    }
  }


  guardarTipografia(): void {
    this.tipografiaSeleccionada = this.infoForm.get('tipografia')?.value;
    console.log('Tipografía seleccionada guardada:', this.tipografiaSeleccionada);
  }

  guardarColores(): void {
    this.color1 = this.infoForm.get('color1')?.value;
    this.color2 = this.infoForm.get('color2')?.value;
    console.log('Color Primario guardado:', this.color1);
    console.log('Color Secundario guardado:', this.color2);
  }

  sanitizeFile(file: File): SafeUrl {
    const url = URL.createObjectURL(file);
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  onTipoRecursoChange(event: any) {
    console.log('Tipo de recurso seleccionado:', event.value);
    this.tipoRecursoSeleccionado = event.value;
    
    // Si quieres actualizar el formulario también
    if (this.infoForm.get('tipoRecurso')) {
      this.infoForm.patchValue({
        tipoRecurso: event.value
      }, { emitEvent: false });
    }
  }

  onFile(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (file) {
      const formData = new FormData();
      formData.append('type', 'resources');
      formData.append('file', file); // Agregar el archivo al FormData

      console.log('Subiendo archivo...');

      // Llamar al método de servicio para subir el archivo
      this.landingsService.uploadResource(formData).subscribe(
        (response) => {
          // Aquí se asume que la respuesta contiene la URL del archivo subido
          this.archivoSeleccionado = response.ImageUrl; // Guardar la URL en lugar del archivo
          console.log('Archivo subido con éxito. URL:', this.archivoSeleccionado);
        },
        (error) => {
          console.error('Error al subir el archivo:', error);
          Notiflix.Notify.failure('Error al subir el archivo');
        }
      );
    }
  }

  mostrarInputRecurso(): boolean {
    return this.tipoRecursoSeleccionado === 'Video' || this.tipoRecursoSeleccionado === 'Ebook' || this.tipoRecursoSeleccionado === 'Documento';
  }
  isFieldInvalid(field: string): boolean {
    const control = this.infoForm.get(field);
    return control?.invalid && control?.touched;
  }
  onSubmit(): void {
    if (this.infoForm.valid && this.selectedProductId) {
      this.formSubmitted = true;
      const bodies = [
        { Property: "Landing-Micronicho", Value: this.infoForm.value.micronicho, ProductId: this.selectedProductId },
        { Property: "Landing-Propuesta", Value: this.infoForm.value.propuesta, ProductId: this.selectedProductId },
        { Property: "Landing-GatilloM", Value: this.infoForm.value.GatilloM, ProductId: this.selectedProductId },
        { Property: "Landing-contador", Value: this.infoForm.value.contador, ProductId: this.selectedProductId },
        { Property: "Landing-resultadoCurso", Value: this.infoForm.value.resultadoCurso, ProductId: this.selectedProductId },
        { Property: "Landing-Deseo", Value: this.infoForm.value.deseo, ProductId: this.selectedProductId },
        { Property: "Landing-Problema", Value: this.infoForm.value.problema, ProductId: this.selectedProductId },
        { Property: "Landing-Descripcion", Value: this.infoForm.value.descripcion, ProductId: this.selectedProductId },
        // { Property: "Landing-Imagen", Value: this.imagenUrl, ProductId: this.selectedProductId },
        { Property: "Landing-Tipo", Value: this.infoForm.value.tipoRecurso, ProductId: this.selectedProductId },
        { Property: "Landing-Recurso", Value: this.resourceUrl, ProductId: this.selectedProductId },
        { Property: "Landing-somos", Value: this.infoForm.value.somos, ProductId: this.selectedProductId },
        { Property: "Landing-FAQS", Value: this.infoForm.value.FAQs, ProductId: this.selectedProductId },
        { Property: "Landing-Color1", Value: this.infoForm.value.color1, ProductId: this.selectedProductId },
        { Property: "Landing-Color2", Value: this.infoForm.value.color2, ProductId: this.selectedProductId },
        { Property: "Landing-Logo", Value: this.infoForm.value.logo, ProductId: this.selectedProductId },
        { Property: "Landing-Tipografia", Value: this.infoForm.value.tipografia, ProductId: this.selectedProductId },
      ];
      // Enviar la solicitud al backend con el ProductId
      this.ProductsService.enviarloanding(bodies).subscribe(
        (response) => {
          // console.log('Formulario enviado correctamente', response);
          Swal.fire({
            title: 'Éxito!',
            text: 'El formulario se ha enviado correctamente.',
            icon: 'success',
            confirmButtonText: 'Aceptar'
          }).then(() => {
            this.router.navigate(['/products/courses']);  // Redirige a la URL deseada
          });
        },
        (error) => {
          console.error('Error al enviar el formulario', error);
          Swal.fire({
            title: 'Error!',
            text: 'Ocurrió un problema al enviar el formulario.',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        }
      );
    } else {
      // console.log('El formulario o el ProductId es inválido');
      this.formSubmitted = false;
    }
  }
  goToNextStep(e: any) {
    this.stepper.next();
    if (e == 1 || e == 2) {
      Notiflix.Notify.success('Plantilla seleccionada');
      this.crearPlantilla = false;
    } else {
      this.crearPlantilla = true;
    }
  }

  remove(fruit): void {
    const index = this.queObtendras.indexOf(fruit);

    if (index >= 0) {
      this.queObtendras.splice(index, 1);
      this.creacionLandingService.updateQueObtendras(this.queObtendras);
      // this.updatePromptPalabrasClave();
    }
    // this.actionName = false;
    // this.botonGuardarAvatar = false;
  }

  add(event: MatChipInputEvent): void {
    // console.log(event.value);
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.queObtendras.push({ name: value.trim() });
      this.creacionLandingService.updateQueObtendras(this.queObtendras);
      // this.updatePromptPalabrasClave();
      // this.botonGuardarAvatar = false;
    }
    // console.log(this.queObtendras);

    // Reset the input value
    if (input) {
      input.value = '';
    }
    // this.actionName = false;
  }
  removeImage(type): void {
    if (type == 'imagenCurso') {
      this.selectedImage = null;
      this.infoForm.get('imagen').reset();
    } else if (type == 'imgObtendras') {
      this.imgObtendras = null;
      this.infoForm.get('imgObtendras').reset();
    } else if (type == 'imagenUsuario') {
      this.imagenUsuario = null;
      this.infoForm.get('imagenUsuario').reset();
    } else if (type == 'logo') {
      this.logo = null;
      this.infoForm.get('logo').reset();
    }
  }
  addImage(): void {
    if (this.respaldo) {
      this.respaldoImages.push(this.respaldo);
      // console.log(this.respaldoImages);
      this.removeImage('respaldo'); // Opcional: limpiar el campo después de agregar la imagen
    }
  }

  removeImageMulty(index: number): void {
    this.multipleImages.splice(index, 1);
  }

  addPreguntaRespuesta(): void {
    this.preguntaRespuestaForm = this.fb.group({
      pregunta: ['',],
      respuesta: ['',]
    });

    this.preguntasRespuestas.push(this.preguntaRespuestaForm);
    // console.log(this.preguntasRespuestas);
  }

  removePreguntaRespuesta(index: number): void {
    this.preguntasRespuestas.removeAt(index);
  }

  comprobarDatos() {

  }

  // Modificar el método generarLanding para manejar la actualización
  generarLanding() {
    Notiflix.Loading.circle(this.isEditing ? 'Actualizando landing page...' : 'Guardando landing page...');
    
    // Preparar los datos
    const landingData = [
      { id: null, Property: "Landing-Gancho", Value: this.gancho, ProductId: this.selectedProductId },
      { id: null, Property: "Landing-Propuesta", Value: this.propuesta, ProductId: this.selectedProductId },
      { id: null, Property: "Landing-Deseo", Value: this.deseo, ProductId: this.selectedProductId },
      { id: null, Property: "Landing-Imagen", Value: this.selectedImage, ProductId: this.selectedProductId },
      { id: null, Property: "Landing-QueObtendras", Value: this.queObtendras.map(item => item.name).join(','), ProductId: this.selectedProductId },
      { id: null, Property: "Landing-GM", Value: 'De $99 USD a tan solo $27 USD, por tiempo limitado.', ProductId: this.selectedProductId },
      { id: null, Property: "Landing-ImgObtendras", Value: this.imgObtendras, ProductId: this.selectedProductId },
      { id: null, Property: "Landing-Respaldo", Value: this.imagenesRespaldo.length ? this.imagenesRespaldo.join(',') : 'Sin imágenes de respaldo', ProductId: this.selectedProductId },
      { id: null, Property: "Landing-Somos", Value: this.somos, ProductId: this.selectedProductId },
      { id: null, Property: "Landing-Recursos", Value: this.imagenesRecRegalo.length ? this.imagenesRecRegalo.join(',') : 'Sin recursos de regalo', ProductId: this.selectedProductId },
      { id: null, Property: "Landing-Pregunta", Value: this.preguntasRespuestas.value.map(item => item.pregunta).filter(pregunta => pregunta).join('-'), ProductId: this.selectedProductId },
      { id: null, Property: "Landing-Respuesta", Value: this.preguntasRespuestas.value.map(item => item.respuesta).filter(respuesta => respuesta).join('-'), ProductId: this.selectedProductId },
      { id: null, Property: "Landing-Archivo", Value: this.archivoSeleccionado, ProductId: this.selectedProductId },
      { id: null, Property: "Landing-ImagenUsuario", Value: this.imagenUsuario, ProductId: this.selectedProductId },
      { id: null, Property: "Landing-Color1", Value: this.color1, ProductId: this.selectedProductId },
      { id: null, Property: "Landing-Color2", Value: this.color2, ProductId: this.selectedProductId },
      { id: null, Property: "Landing-Logo", Value: this.logo, ProductId: this.selectedProductId },
      { id: null, Property: "Landing-Tipografia", Value: this.tipografiaSeleccionada, ProductId: this.selectedProductId },
      { id: null, Property: "Landing-Tipo", Value: this.tipoRecursoSeleccionado, ProductId: this.selectedProductId }
    ];
  
    // Si estamos editando, necesitamos asignar los IDs correspondientes
    if (this.isEditing) {
      // Obtener los datos actuales de la landing
      this.landingsService.getLandingPage(this.landingSlug).subscribe({
        next: (response) => {
          // Asignar los IDs correspondientes a cada propiedad
          response.ladingData.forEach(item => {
            const matchingData = landingData.find(data => data.Property === item.Property);
            if (matchingData) {
              matchingData.id = item.id;
            }
          });
  
          // Ahora actualizamos usando el endpoint de actualización
          const requests = landingData.map(data => {
            if (data.id) {
              // Si tiene ID, actualizar
              return this.landingsService.updateProductInfo(data);
            } else {
              // Si no tiene ID, crear nuevo
              return this.landingsService.setProductInfo(data);
            }
          });
  
          forkJoin(requests).subscribe(
            (results) => {
              Notiflix.Loading.remove();
              Notiflix.Notify.success('Landing page actualizada con éxito');
              console.log('Resultados de la actualización:', results);
            },
            (error) => {
              console.error('Error al actualizar los datos:', error);
              Notiflix.Loading.remove();
              Notiflix.Notify.failure('Error al actualizar los datos');
            }
          );
        },
        error: (error) => {
          console.error('Error al obtener datos actuales:', error);
          Notiflix.Loading.remove();
          Notiflix.Notify.failure('Error al obtener datos actuales');
        }
      });
    } else {
      // Si es una nueva landing, usar el método original de creación
      const requests = landingData.map(data => this.landingsService.setProductInfo(data));
      
      forkJoin(requests).subscribe(
        (results) => {
          Notiflix.Loading.remove();
          Notiflix.Notify.success('Landing page creada con éxito');
          console.log(results);
        },
        (error) => {
          console.error('Error al guardar los datos:', error);
          Notiflix.Loading.remove();
          Notiflix.Notify.failure('Error al guardar los datos');
        }
      );
    }
  }
}