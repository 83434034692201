import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { StudentsRoutingModule } from './students-routing.module';
import { StudentsComponent } from './students.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';  // Importa MatFormFieldModule
import { MatSelectModule } from '@angular/material/select';  // Importa MatSelectModule
import { MatOptionModule } from '@angular/material/core';  // Importa MatOptionModule
import { MatTooltipModule } from '@angular/material/tooltip';

// Importa otros componentes relacionados con estudiantes aquí
// import { StudentDetailComponent } from './student-detail/student-detail.component';
// import { StudentFormComponent } from './student-form/student-form.component';

@NgModule({
  declarations: [StudentsComponent],
  imports: [
    CommonModule,
    FormsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    StudentsRoutingModule,
    CKEditorModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatTooltipModule,
  ],
})
export class StudentsModule { }
