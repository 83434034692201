import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { map, catchError } from 'rxjs/operators';
import { APIService } from 'src/app/shared/service/apiService.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls:['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public loginForm: FormGroup;
  public registerForm: FormGroup;
  public isLoading = false;
  public submitted = false;
  public submittedLogin = false;

  private subs: Array<Subscription> = [];

  constructor(
    private formBuilder: FormBuilder,
    private authService: APIService,
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', Validators.required]
    })

    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      businessname: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', Validators.required],
      confirmedPassword: ['', [Validators.required]]
    })

  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  public get formState() {
    return this.loginForm.controls;
  }

  private validatePasswords(): void {
    const password: string = this.registerForm.get('password').value;
    const confirmPassword: string = this.registerForm.get('confirmedPassword').value;
    if (password !== confirmPassword) {
      this.registerForm.controls['confirmedPassword'].setErrors({ incorrect: true });
    };
  }

  private buildUser() {
    return {
      firstName: this.registerForm.controls['firstName'].value,
      lastName: this.registerForm.controls['lastName'].value,
      businessname: this.registerForm.controls['businessname'].value,
      password: this.registerForm.controls['password'].value,
      email: this.registerForm.controls['email'].value
    }
  }

  private loginUser() {
    return {
      email: this.loginForm.controls['email'].value,
      password: this.loginForm.controls['password'].value
    }
  }

  onSubmit() {
    this.submittedLogin = true;

    if (this.loginForm.invalid) {
      return;
    }

    const user: any = this.loginUser();
    this.isLoading = true;
    this.subs.push(
      this.authService.login(user)
        .pipe(
          map((data: any) => {
            // console.log( data );
            if (data.Message) {
              Swal.fire({
                title: 'Error de Acceso',
                text: 'Error al acceder. Ingresa datos validos.',
                icon: 'error',
                confirmButtonText: 'OK'
              });
              this.isLoading = false;
              return;
            }
           localStorage.setItem('currentUser', JSON.stringify(data));
            this.router.navigateByUrl("/dashboard");
          }),
           // Guardar el token JWT en localStorage
          // localStorage.setItem('token', data.token); // Asegúrate de que el token venga en 'data.token'
           //this.router.navigateByUrl("/dashboard");
         //}),
          catchError(err => {
            this.isLoading = false;
            Swal.fire({
              title: 'Error de Acceso',
              text: 'Verifica que tus datos sean correctos.',
              icon: 'error',
              confirmButtonText: 'OK'
            });
            return throwError(err);
          })
        ).subscribe()
    );
  }

  /* registerUser() {
    this.submitted = true;
    this.validatePasswords();

    if (this.registerForm.invalid) {
      return;
    }
    const user: any = this.buildUser();
    this.isLoading = true;
    this.subs.push(
      this.authService.signUp(user)
        .pipe(
          map(() => {
            this.handleUserCreation();
            setTimeout(() => {
              window.location.reload();
            }, 1800);
          }),
          catchError(error => this.handleError(error))
        )
        .subscribe()
    );
  } */

}
