import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { getHeaders } from '../utils/headers.functions';

const BASE_URL: string = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class LandingsService {

  constructor(private http: HttpClient) { }


  getLandinPage(slug: any){
    return this.http.get<any>(`${BASE_URL}/landing/${slug}`);
  }


  setProductInfo(body: any){
    const headers: HttpHeaders = getHeaders();
    return this.http.post<any>(`${BASE_URL}/info-product`, body, { headers });
}
}
